
<template>
<div>
    <div class="row">
      <div class="col-12">
        <h5 class="mb-3">Payments list</h5>
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12 col-md-8">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex mr-2 align-items-center">
                    <b-form-select v-model="itemsPerPage" size="sm" :options="pageOptions"></b-form-select>
                  </label>
                  <b-dropdown variant="light" size="sm" left>
                    <template slot="button-content">
                      <i class="mdi mdi-filter-variant"></i>
                    </template>
                    <b-dropdown-item-button @click="selectAllRows()"><i :class="isSelectAll? 'mdi mdi-playlist-remove' : 'mdi mdi-playlist-check'" ></i> {{isSelectAll? 'DeSelect All' : 'Select All' }}</b-dropdown-item-button>
                  </b-dropdown>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div class="">
                  <label class="d-block">
                    <b-form-input v-model="searchQuery" @input="updateSearchQuery" type="search" placeholder="Search..." class="form-control form-control-sm"></b-form-input>
                  </label>
                </div>
              </div>
            </div>
            <div class="table-responsive mb-0 loading-viewport">
              <is-loading v-if="isLoading" :box="true" />
              <b-table striped hover table-class="table font-sm table-centered w-100" thead-tr-class=""
                  ref="selectableTable" :items="pageData.data" :fields="columns" responsive="sm"  >
                  <template v-slot:cell(check)="data">
                      <div class="custom-control custom-checkbox text-center">
                          <input v-model="selected" type="checkbox" :value="data.item.id" class="custom-control-input" :id="`checkorder${data.item.id}`" />
                          <label class="custom-control-label" :for="`checkorder${data.item.id}`">{{data.item.id}}</label>
                      </div>
                  </template>
                  <template v-slot:cell(payer)="data">
                      <h5 class="m-0 d-inline-block cursor-pointer align-middle">
                          <span class="text-dark font-sm">{{ data.item.payer.first_name }}</span>
                      </h5>
                  </template>
                  <template v-slot:cell(reference)="data">
                    <h5 :id="'pay-dec-'+data.item.id" class="m-0 d-inline-block cursor-pointer align-middle">
                      <span class="text-dark d-block font-sm">PID: {{ data.item.paymentid }}</span>
                      <span class="text-dark mt-1 d-block font-sm">TF: {{ data.item.transref }}</span>
                    </h5>
                    <b-tooltip :target="'pay-dec-'+data.item.id">
                      {{ data.item.desc }}
                    </b-tooltip>
                  </template>
                  <template v-slot:cell(paid_amount)="data">
                    <h5 class="m-0 d-inline-block cursor-pointer align-middle">
                      <span class="text-dark font-sm">${{ data.item.paid_amount }}</span>
                    </h5>
                  </template>
                  <template v-slot:cell(payvia)="data">
                    <h5 class="m-0 d-inline-block cursor-pointer align-middle">
                      <span class="text-success d-block font-sm">{{ data.item.payvia }}</span>
                      <span class="text-dark d-block" style="font-size: 10px;">{{ data.item.method }}</span>
                    </h5>
                  </template>
                  <template v-slot:cell(currency)="data">
                    <h5 class="m-0 d-inline-block cursor-pointer align-middle">
                      <span class="text-dark font-sm">{{ data.item.currency }}</span>
                    </h5>
                  </template>
                  <template v-slot:cell(date)="data">
                    <span class="cursor-pointer">{{data.item.created_at | date_ago}} </span>
                  </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="paging_simple_numbers float-right">
                  <b-pagination v-model="currentPage" class="pagination-rounded mb-0"
                    :total-rows="pageData.total" :limit="3" :per-page="itemsPerPage"></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import IsLoading from "@/components/IsLoading.vue"
import _ from 'lodash';

export default{
  components:{
    IsLoading
  },
  data() {
    return {
      isLoading: false,
      pageData: {
        data:[]
      },
      searchQuery: "",
      pageOptions: [10, 25, 50, 100],
      selected: [],
      isSelectAll: false,
      columns: [
          {
            key: "check",
            label: "ID"
          },
          {
            key: "payer",
            sortable: true
          },
          {
            key: "reference",
            label: "References",
            sortable: true
          },
          {
            key: "paid_amount",
            label: "Amount",
            sortable: true
          },
          {
            key: "payvia",
            label: "Pay Via",
            sortable: true
          },
          {
            key: "currency",
            sortable: true
          },
          {
            key: "date",
            label: "Date",
            sortable: true
          },
      ]
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchPayments(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchPayments(1, val)
      }
    },
  },
  methods: {
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.pageData.data.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchPayments()
    }, 2000),
    fetchPayments(page, per_page = null) {
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$http.get(`/payments?${new URLSearchParams(payload).toString()}`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.pageData = response.data.data;
        }
      })
    },
  },
  mounted() {
    this.fetchPayments()
  },
}
</script>
